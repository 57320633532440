/*  Montserrat Medium */

@font-face {
  font-family: "Montserrat Medium";
  src: url("../fonts/Montserrat-Medium.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/Montserrat-Medium.woff") format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/Montserrat-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Montserrat Regular */

@font-face {
  font-family: "Montserrat Regular";
  src: url("../fonts/Montserrat-Regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/Montserrat-Regular.woff") format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/Montserrat-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Montserrat Bold */

@font-face {
  font-family: "Montserrat Bold";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}
