body,
html {
  min-width: 320px;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-color: #ffffff;
  font-size: 16px;
  font-family: "Montserrat Regular";
}

//Base fonts start.

$font-mon-reg: "Montserrat Regular";
$font-mon-med: "Montserrat Medium";
$font-mon-bold: "Montserrat Bold";

//Base fonts end.

// Media screen size start.
$sz-1599: 1599px;
$sz-1399: 1399px;
$sz-1199: 1199px;
$sz-991: 991px;
$sz-768: 768px;
$sz-575: 575px;
$sz-420: 420px;
// Media screen size end.

a {
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.unscroll {
  overflow: hidden;
}

input::-webkit-input-placeholder {
  opacity: 0;
}
input:focus::-webkit-input-placeholder {
  opacity: 1;
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1550px;
  }
}
textarea,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
}
// @media screen and (max-width: 1680px){
//   body,
//   html{
//     font-size: 15px;
//   }
// }
// @media screen and (max-width: 1600px){
//   body,
//   html{
//     font-size: 14px;
//   }
// }
// @media screen and (max-width: 1440px){
//   body,
//   html{
//     font-size: 13px;
//   }
// }
// @media screen and (max-width: 1366px){
//   body,
//   html{
//     font-size: 12px;
//   }
// }
// @media screen and (max-width: 991px){
//   body,
//   html{
//     font-size: 11px;
//   }
// }
