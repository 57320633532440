.body-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #070a10;
  opacity: 0.64;
  z-index: 99;
}
.pop-up-modal {
  width: 40vw;
  height: 60vh;
  padding: 30px 36px 0 57px;
  display: none;
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #ffffff;
  h3 {
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    font-family: $font-mon-bold;
    position: relative;
    margin-bottom: 0;
    &::after {
      position: absolute;
      content: "";
      width: 24px;
      height: 2px;
      background-color: #ebb85e;
      left: 0;
      bottom: -13.5px;
    }
  }
  .pop-up-modal-content {
    margin-top: 20px;
    overflow-y: scroll;
    height: 85%;
    p {
      font-size: 16px;
      color: #282523;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
  .pop-up-modal-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #e6e6e6;
    background-color: #f5f5f5;
    border-radius: 12px;
  }

  .pop-up-modal-content::-webkit-scrollbar {
    width: 5px;
    background-color: #e6e6e6;
  }

  .pop-up-modal-content::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #cccccc;
  }

  .close-pop-up-btn {
    cursor: pointer;
    position: absolute;
    right: 26px;
    top: 25px;
    width: 26px;
    height: 26px;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
  }
  .close-pop-up-btn:before,
  .close-pop-up-btn:after {
    position: absolute;
    left: 11px;
    top: 4px;
    content: " ";
    height: 16px;
    width: 2px;
    background-color: #c6c6c6;
  }
  .close-pop-up-btn:before {
    transform: rotate(45deg);
  }
  .close-pop-up-btn:after {
    transform: rotate(-45deg);
  }
}

.navigation-menu-block {
  display: none;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(55, 72, 99);
  background: linear-gradient(180deg, rgba(55, 72, 99, 1) 0%, rgba(60, 72, 95, 1) 100%);
  .container {
    position: relative;
    height: 100vh;
  }
  &__top {
    .close {
      position: absolute;
      left: 16px;
      top: 50px;
      width: 28px;
      height: 20px;
      opacity: 1;
    }
    .close:before,
    .close:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 28px;
      width: 2px;
      background-color: #ffffff;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
    .nav-header-logo-wrapper {
      padding-top: 20px;
      text-align: center;
      .header-logo-desktop {
        display: block;
      }
      .header-logo-mobile {
        display: none;
      }
    }
  }
  &__middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .nav-links-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        font-size: 30px;
        line-height: 70px;
        color: #ffffff;
        margin-bottom: 12px;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #ebb85e;
        }
      }
    }
    .nav-active {
      &::before {
        position: absolute;
        content: "";
        width: 29px;
        height: 70px;
        left: -29px;
        background-color: inherit;
        border-top: 3px solid #ebb85e;
        border-left: 3px solid #ebb85e;
        border-bottom: 3px solid #ebb85e;
      }
      &::after {
        position: absolute;
        content: "";
        width: 29px;
        height: 70px;
        right: -29px;
        background-color: inherit;
        border-top: 3px solid #ebb85e;
        border-right: 3px solid #ebb85e;
        border-bottom: 3px solid #ebb85e;
      }
    }
  }
  &__bottom {
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-contacts-desctop {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fb-logo {
        margin-right: 170px;
        @media screen and (max-width: $sz-1199) {
          margin-right: 50px;
        }
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        font-family: $font-mon-med;
        transition: 0.3s ease-in-out;
        svg {
          path {
            transition: 0.3s ease-in-out;
          }
        }
        &:hover {
          color: #ebb85e;
          svg {
            path {
              stroke: #ebb85e;
            }
          }
        }
        span {
          display: inline-block;
          padding-right: 15px;
          padding-left: 11px;
        }
      }
    }
    .nav-contacts-mobile {
      display: none;
    }
    a {
      font-size: 16px;
      font-family: $font-mon-med;
      color: #ffffff;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #ebb85e;
        svg {
          path {
            stroke: #ebb85e;
          }
        }
      }
    }
    span {
      font-size: 16px;
      color: #ffffff;
      svg {
        path {
          transition: 0.3s ease-in-out;
        }
      }
    }
    .address {
      padding-right: 30px;
      span {
        padding-right: 15px;
      }
    }
  }
}

.header-section {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: transparent;
  &__content {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px 0;
    position: relative;
    height: 98px;
    .nav-menu-btn-container {
      margin-bottom: 17px;
      .nav-menu-btn-wrapp {
        cursor: pointer;
        display: inline-block;
        .nav-menu-btn {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 2px;
          background-color: #ffffff;
          &::before {
            position: absolute;
            content: "";
            width: 30px;
            height: 2px;
            background-color: #ffffff;
            top: -7px;
          }
          &::after {
            position: absolute;
            content: "";
            width: 30px;
            height: 2px;
            background-color: #ffffff;
            top: 7px;
          }
        }
      }
    }
    .header-logo-wrapper {
      // width: 100%;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      .header-logo-desktop {
        display: block;
      }
      .header-logo-mobile {
        display: none;
      }
      a {
        display: inline-block;
      }
    }
    .header-contacts {
      text-align: right;
      // width: 100%;
      a {
        font-family: $font-mon-med;
        color: #ffffff;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #ebb85e;
        }
      }
      .header-contacts-desktop {
        #online-consult-header {
          margin-left: 20px;
          font-family: $font-mon-bold;
          font-size: 14px;
          color: #ffffff;
          padding: 14px 23px;
          border: 2px solid #ffffff;
          transition: 0.3s ease-in-out;
          &:hover {
            border-color: #ebb85e;
          }
        }
        .fb-logo {
          margin-left: 30px;
          display: inline-block;
          svg {
            margin-right: 4px;
            height: 27px;
            path {
              transition: all 0.3s ease-in-out;
              fill: transparent;
            }
          }
          &:hover {
            svg {
              height: 27px;
              path {
                stroke: #ebb85e;
                fill: #ebb85e;
              }
            }
          }
        }
      }
      .header-contacts-mobile {
        display: none;
      }
      .fb-logo-mobile {
        margin-left: 12px;
        display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #334055;
        svg {
          height: 27px;
          path {
            transition: all 0.3s ease-in-out;
            fill: #ffffff;
          }
        }
        &:hover {
          svg {
            height: 27px;
            path {
              fill: #ebb85e;
            }
          }
        }
      }
      .phones-block {
        display: none;
      }
    }
  }
}
.header-section.header-black {
  .nav-menu-btn-container {
    .nav-menu-btn-wrapp {
      .nav-menu-btn {
        background-color: #707070;
        &::before {
          background-color: #707070;
        }
        &::after {
          background-color: #707070;
        }
      }
    }
  }
  .header-logo-wrapper {
    a {
      svg {
        path {
          fill: #3d4044;
        }
      }
    }
  }
  .header-contacts {
    svg {
      path {
        stroke: #3d4044;
      }
    }
    .fb-logo {
      svg {
        path {
          fill: transparent;
        }
      }
    }
    .fb-logo-mobile {
      background-color: #ffffff;
      svg {
        path {
          fill: transparent;
        }
      }
    }
    #online-consult-header {
      color: #3d4044;
      border-color: #3d4044;
    }
    a {
      color: #3d4044;
      &:hover {
        color: #ebb85e;
      }
    }
  }
}

.main-content {
  background: rgb(255, 252, 252);
  background: linear-gradient(180deg, rgba(255, 252, 252, 1) 0%, rgba(220, 229, 224, 1) 100%);
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  margin: auto;
}
.content-block {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s ease-in-out;
  transform: translate(-100%, 0);
}
.content-block.active {
  transform: translate(0, 0);
  & ~ .content-block {
    transform: translate(100%, 0);
  }
}

.background-img {
  top: 10% !important;
  left: -10% !important;
  width: 100%;
  height: 700px;
}
.back-decktop {
  position: absolute !important;
  top: 50%;
}

.home {
  background: rgb(55, 72, 99);
  background: linear-gradient(180deg, rgba(55, 72, 99, 1) 0%, rgba(60, 72, 95, 1) 100%);
  .background-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    .back-decktop {
      display: block;
    }
    .back-mobile {
      display: none;
    }
  }
  &__content {
    text-align: center;
    &__title {
      margin-bottom: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__actions {
      display: inline-block;
      a {
        font-family: $font-mon-bold;
        font-size: 14px;
        color: #ffffff;
        padding: 17px 23px 15px;
        border: 2px solid #ffffff;
        transition: 0.3s ease-in-out;
        &:hover {
          border-color: #ebb85e;
        }
      }
      .online-consult {
        margin-right: 29px;
      }
    }
    h1 {
      font-family: $font-mon-bold;
      font-size: 70px;
      color: #ffffff;
      display: inline-block;
      position: relative;
      margin-bottom: 0;
      line-height: 100px;
      &::before {
        position: absolute;
        content: "";
        width: 29px;
        height: 100%;
        left: -29px;
        background-color: inherit;
        border-top: 3px solid #ebb85e;
        border-left: 3px solid #ebb85e;
        border-bottom: 3px solid #ebb85e;
      }
      &::after {
        position: absolute;
        content: "";
        width: 29px;
        height: 100%;
        right: -29px;
        background-color: inherit;
        border-top: 3px solid #ebb85e;
        border-right: 3px solid #ebb85e;
        border-bottom: 3px solid #ebb85e;
      }
    }
  }
}
.about {
  .background-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    .back-decktop {
      display: block;
    }
    .back-mobile {
      display: none;
    }
  }
  &__content {
    height: 100%;
    margin: auto 0;
    padding-left: 70px;
    padding-top: 28px;
    padding-bottom: 28px;
    h2 {
      font-size: 60px;
      font-family: "Montserrat Bold";
      color: #282523;
      margin-bottom: 15px;
    }
    p {
      color: #282523;
      font-size: 16px;
      line-height: 20px;
    }
    .about-content-wrapper {
      &::before {
        position: absolute;
        content: "";
        width: 29px;
        height: 100%;
        top: 0;
        left: 14px;
        background-color: inherit;
        border-top: 3px solid #ebb85e;
        border-left: 3px solid #ebb85e;
        border-bottom: 3px solid #ebb85e;
      }
    }
  }
  &__banner {
    position: relative;
    .banner-decoration {
      position: absolute;
      z-index: -1;
      right: -20px;
      top: -24px;
    }
  }
}
.gallery {
  background: rgb(55, 72, 99);
  background: linear-gradient(180deg, rgba(55, 72, 99, 1) 0%, rgba(60, 72, 95, 1) 100%);
  .background-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    .back-decktop {
      display: block;
    }
    .back-mobile {
      display: none;
    }
  }
  .gallery-wrapper {
    text-align: center;
    margin-top: 15px;
  }
  &__title {
    display: inline-block;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      width: 29px;
      height: 100%;
      left: -29px;
      background-color: inherit;
      border-top: 3px solid #ebb85e;
      border-left: 3px solid #ebb85e;
      border-bottom: 3px solid #ebb85e;
    }
    &::after {
      position: absolute;
      content: "";
      width: 29px;
      height: 100%;
      top: 0;
      right: -29px;
      background-color: inherit;
      border-top: 3px solid #ebb85e;
      border-right: 3px solid #ebb85e;
      border-bottom: 3px solid #ebb85e;
    }
    h2 {
      font-size: 60px;
      line-height: 100px;
      margin-bottom: 0;
      color: #ffffff;
      font-family: "Montserrat Bold";
    }
  }
  .gallery-slider-desktop {
    margin-top: 45px;
    a {
      padding: 0 17.5px 30px;
      &:focus {
        outline: none;
      }
    }
    #slick-slide-control01 {
      margin: 0 6px;
    }
    .slick-slide {
      &:focus {
        outline: none;
      }
      div {
        &:focus {
          outline: none;
        }
      }
    }
    .slick-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        position: relative;
        color: transparent;
        width: 13px;
        height: 13px;
        font-size: 0px;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        &::before {
          top: 0;
          color: transparent;
          position: absolute;
          content: "";
          width: 13px;
          height: 13px;
          border: 1px solid #ffffff;
          border-radius: 50%;
        }
        &:focus {
          outline: none;
        }
      }
      .slick-active {
        button {
          &::before {
            background-color: #ffffff;
          }
        }
      }
    }
  }
  .gallery-slider-mobile {
    display: none;
  }
}

.contacts {
  .background-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    .back-decktop {
      display: block;
    }
    .back-mobile {
      display: none;
    }
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .contacts-title {
      margin-left: 29px;
      position: relative;
      h2 {
        font-size: 60px;
        line-height: 100px;
        margin-bottom: 0;
        color: #282523;
        font-family: "Montserrat Bold";
        &::before {
          position: absolute;
          content: "";
          top: 0;
          width: 29px;
          height: 100%;
          left: -29px;
          background-color: inherit;
          border-top: 3px solid #ebb85e;
          border-left: 3px solid #ebb85e;
          border-bottom: 3px solid #ebb85e;
        }
        &::after {
          position: absolute;
          content: "";
          width: 29px;
          height: 100%;
          top: 0;
          right: -29px;
          background-color: inherit;
          border-top: 3px solid #ebb85e;
          border-right: 3px solid #ebb85e;
          border-bottom: 3px solid #ebb85e;
        }
      }
    }
    .contacts-info {
      margin-left: 116px;
      .contacts-info-desktop {
        display: block;
        div {
          &:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
      .contacts-info-mobile {
        display: none;
      }

      span {
        margin-right: 22px;
        svg {
          path {
            stroke: #ebb85e;
            fill: #ebb85e;
          }
        }
      }
      a {
        color: #282523;
        font-size: 20px;
        line-height: 24px;
        font-family: "Montserrat Medium";
      }
    }
  }
  &__map {
    margin-top: 36px;
    margin-bottom: 8px;
    #map {
      height: 477px;
      width: 100%;
      border: 5px solid #ffffff;
    }
    .leaflet-bottom {
      display: none;
    }
  }
}
.consultation {
  background: rgb(55, 72, 99);
  background: linear-gradient(180deg, rgba(55, 72, 99, 1) 0%, rgba(60, 72, 95, 1) 100%);
  .background-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    .back-decktop {
      display: block;
    }
    .back-mobile {
      display: none;
    }
  }
  .container {
    margin-top: 40px;
  }
  &__content {
    margin: 0 auto;
    &__title {
      margin-bottom: 22px;
      text-align: center;
      h2 {
        text-align: center;
        font-size: 60px;
        line-height: 100px;
        color: #ffffff;
        margin-bottom: 0;
        font-family: "Montserrat Bold";
        @media screen and (max-width: $sz-1199) {
          font-size: 52px;
        }
        &::before {
          position: absolute;
          content: "";
          top: 0;
          width: 29px;
          height: 100px;
          left: 15px;
          background-color: inherit;
          border-top: 3px solid #ebb85e;
          border-left: 3px solid #ebb85e;
          border-bottom: 3px solid #ebb85e;
        }
        &::after {
          position: absolute;
          content: "";
          width: 29px;
          height: 100px;
          top: 0;
          right: 15px;
          background-color: inherit;
          border-top: 3px solid #ebb85e;
          border-right: 3px solid #ebb85e;
          border-bottom: 3px solid #ebb85e;
        }
      }
    }
    &__form {
      form {
        text-align: center;
        p {
          text-align: center;
          color: #ffffff;
          font-family: $font-mon-med;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 37px;
        }
        .user-name,
        .user-data {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 6px;
          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            label {
              color: #d8d8d8;
              font-size: 16px;
              margin-bottom: 0px;
              padding-left: 10px;
            }
            input {
              background-color: inherit;
              border: 0;
              border-bottom: 1px solid #000000;
              width: 345px;
              color: #ffffff;
              padding-bottom: 9px;
              padding-left: 10px;
              transition: all 0.3s ease-in-out;
              &:focus {
                outline: none;
                border-bottom-color: #ebb85e;
              }
            }
          }
        }
        .user-name {
          margin-bottom: 30px;
        }
        .user-data {
          margin-bottom: 35px;
        }
        .text-area-wrapp {
          margin-bottom: 24px;
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          label {
            color: #d8d8d8;
            font-size: 16px;
            margin-bottom: 9px;
            padding-left: 16px;
          }
          textarea {
            width: 100%;
            background-color: inherit;
            border: 1px solid #000000;
            transition: all 0.3s ease-in-out;
            color: #ffffff;
            max-height: 163px;
            padding: 10px;
            resize: none;
            &:focus {
              outline: none;
              border-color: #ebb85e;
            }
          }
        }
        .form-submit-btn {
          color: #ffffff;
          font-size: 14px;
          font-family: "Montserrat Bold";
          height: 50px;
          width: 198px;
          background-color: inherit;
          border: 1px solid #ebb85e;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #ebb85e;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
.info {
  .background-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    .back-decktop {
      display: block;
    }
    .back-mobile {
      display: none;
    }
  }
  &__content {
    &__title {
      margin-bottom: 90px;
      text-align: center;

      h2 {
        display: inline-block;
        position: relative;
        text-align: center;
        font-size: 60px;
        line-height: 100px;
        color: #282523;
        margin-bottom: 0;
        font-family: "Montserrat Bold";
        &::before {
          position: absolute;
          content: "";
          top: 0;
          width: 29px;
          height: 100px;
          left: -29px;
          background-color: inherit;
          border-top: 3px solid #ebb85e;
          border-left: 3px solid #ebb85e;
          border-bottom: 3px solid #ebb85e;
        }
        &::after {
          position: absolute;
          content: "";
          width: 29px;
          height: 100px;
          top: 0;
          right: -29px;
          background-color: inherit;
          border-top: 3px solid #ebb85e;
          border-right: 3px solid #ebb85e;
          border-bottom: 3px solid #ebb85e;
        }
      }
    }
    &__main {
      margin-bottom: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        max-width: 372px;
        background-color: #ffffff;
        padding: 31px 50px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
          text-align: center;
          color: #282523;
          font-size: 20px;
          line-height: 24px;
          font-family: "Montserrat Bold";
          margin-bottom: 39px;
          position: relative;
          &::after {
            position: absolute;
            content: "";
            width: 48px;
            height: 3px;
            background-color: #ebb85e;
            left: 50%;
            bottom: -13.5px;
            transform: translate(-50%, -50%);
          }
        }
        p {
          text-align: center;
          color: #282523;
          font-size: 16px;
          line-height: 24px;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Montserrat Bold";
          font-size: 14px;
          line-height: 18px;
          height: 50px;
          width: 198px;
          border: 2px solid #ebb85e;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #ebb85e;
          }
        }
      }
      .price-info {
        margin-right: 20px;
      }
    }
  }
}

.footer-section {
  position: fixed;
  width: 100%;
  bottom: 50px;
  left: 0;
  z-index: 20;
  .consult-link-mobile {
    display: none;
  }
  .consult-link-desctop {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -87px;
    right: 15px;
    a {
      display: flex;
      align-items: center;
    }
    svg {
      path {
        transition: fill 0.3s ease-in-out;
      }
      g {
        transition: fill 0.3s ease-in-out;
      }
    }
    span {
      padding-right: 16px;
      font-size: 16px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      font-family: $font-mon-med;
      color: #ffffff;
    }
    &:hover {
      span {
        opacity: 1;
      }
      svg {
        #Path_111,
        #Path_112 {
          fill: #ebb85e;
        }
        #Ellipse_21-2 {
          fill: #ffffff;
        }
      }
    }
  }
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .address {
    .fb-logo {
      margin-right: 170px;
      @media screen and (max-width: $sz-1199) {
        margin-right: 50px;
      }
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      font-family: $font-mon-med;
      transition: 0.3s ease-in-out;
      svg {
        path {
          transition: 0.3s ease-in-out;
        }
      }
      &:hover {
        color: #ebb85e;
        svg {
          path {
            stroke: #ebb85e;
          }
        }
      }
      span {
        display: inline-block;
        padding-right: 15px;
        padding-left: 11px;
      }
    }
    a {
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      font-family: $font-mon-med;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #ebb85e;

        svg {
          path {
            stroke: #ebb85e;
          }
        }
      }
      span {
        display: inline-block;
        padding-right: 15px;
        svg {
          path {
            transition: 0.3s ease-in-out;
          }
        }
      }
    }
  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .slide-counter {
      padding-right: 18px;
      font-size: 20px;
      color: #ffffff;
      .curent-slide {
        display: inline-block;
        min-width: 12px;
      }
    }
    .load-line {
      height: 2px;
      width: 155px;
      background-color: #242a31;
      margin-right: 19px;
      position: relative;
      .loaded {
        height: 100%;
        width: 100%;
        background: #ebb85e;
        animation: 0.7s linear infinite loading;
        transform-origin: 0%;
      }

      @keyframes loading {
        0% {
          transform: scaleX(0);
        }
        100% {
          transform: scaleX(100%);
        }
      }
      &::before {
        content: "";
        z-index: -1;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-width: 100%;
        z-index: -1;
        max-width: 100%;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
    }
    .arrow {
      cursor: pointer;
      display: inline-block;
      height: 24px;
      width: 9px;
      position: relative;
      &:hover::before {
        border-color: #ebb85e;
      }
      &::before {
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-right-style: solid;
        border-right-width: 2px;
        border-color: #ffffff;
        content: "";
        display: inline-block;
        height: 13px;
        left: 0;
        position: absolute;
        top: 6px;
        width: 13px;
        -moz-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
      }
    }
    #prev {
      margin-right: 12px;
      &::before {
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
  }
}
.footer-section.footer-black {
  .consult-link-desctop {
    span {
      color: #3d4044;
    }
  }
  .address {
    a {
      color: #3d4044;

      svg {
        path {
          stroke: #0e0e0e;
        }
      }
      &:hover {
        color: #ebb85e;

        svg {
          path {
            stroke: #ebb85e;
          }
        }
      }
      span {
        display: inline-block;
        padding-right: 15px;
        svg {
          path {
            transition: 0.3s ease-in-out;
          }
        }
      }
    }
  }
  .arrows {
    .slide-counter {
      color: #3d4044;
    }
    .load-line {
      background-color: #b9b9b9;
    }
    .arrow {
      &::before {
        border-color: #3d4044;
      }
      &:hover {
        &::before {
          border-color: #ebb85e;
        }
      }
    }
  }
}

@media screen and (max-width: $sz-1599) {
  .about {
    &__banner {
      padding-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .banner-decoration {
        right: 0;
        height: 110%;
        svg {
          height: 100%;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .consultation {
    &__content {
      &__form {
        form {
          .user-name {
            div {
              width: 48%;
              input {
                width: 100%;
              }
            }
          }
          .user-data {
            div {
              width: 48%;
              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .header-section {
    &__content {
      .header-contacts {
        .header-contacts-desktop {
          #fb-text {
            padding-left: 4px;
          }
          .fb-logo {
            margin-left: 12px;
            svg {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $sz-1399) {
  .about__banner {
    padding-right: 70px;
  }
}
@media screen and (max-width: $sz-1199) {
  .header-section {
    &__content {
      padding-left: 0;
      padding-right: 0;
      .nav-menu-btn-container {
        margin-bottom: 7px;
      }
      .header-contacts {
        padding: 0;
        a {
          font-size: 13px;
        }
        .header-contacts-desktop {
          #tel-text {
            display: none;
          }
          #fb-text {
            display: none;
          }
          #online-consult-header {
            font-size: 12px;
            margin-left: 6px;
            padding: 10px 5px 10px;
          }
          .fb-logo {
            margin-left: 7px;
            svg {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .gallery .gallery-slider a {
    padding: 0 5px 8px;
  }
  .about__banner {
    padding-right: 30px;
    .banner-decoration {
      top: 0;
      right: -50px;
      height: 100%;
    }
  }
}

@media screen and (max-width: $sz-991) {
  .background-img {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0;
    width: auto !important;
    height: auto !important ;
    z-index: -1;
  }

  .pop-up-modal {
    width: 90vw;
    height: 82vh;
    padding: 30px 20px 0 22px;
    .close-pop-up-btn {
      top: 29px;
      right: 10px;
    }
  }
  .navigation-menu-block {
    &__top {
      .close {
        width: 20px;
        top: 38px;
      }
      .close:before,
      .close:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 20px;
        width: 2px;
        background-color: #ffffff;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
      .nav-header-logo-wrapper {
        .header-logo-desktop {
          display: none;
        }
        .header-logo-mobile {
          display: block;
        }
      }
    }
    &__middle {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      .nav-links-wrapper {
        a {
          font-size: 14px;
          line-height: 43px;
          margin-bottom: 8px;
        }
      }
      .nav-active {
        &::before {
          height: 43px;
          width: 13px;
          border-top: 2px solid #ebb85e;
          border-left: 2px solid #ebb85e;
          border-bottom: 2px solid #ebb85e;
        }
        &::after {
          width: 13px;
          height: 43px;
          border-top: 2px solid #ebb85e;
          border-right: 2px solid #ebb85e;
          border-bottom: 2px solid #ebb85e;
        }
      }
    }
    &__bottom {
      justify-content: center;
      height: 170px;
      padding-bottom: 20px;
      width: 136%;
      bottom: 0;
      left: -120px;
      background-color: #2f3e55;
      -webkit-box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.36);
      -moz-box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.36);
      box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.36);

      .nav-contacts-desctop {
        display: none;
      }
      .nav-contacts-mobile {
        display: block;
        .fb-logo {
          display: inline-block;
          margin-bottom: 20px;
          span {
            padding-left: 20px;
          }
        }
        .header-contacts {
          span {
            padding-right: 20px;
          }
        }
      }
      a {
        font-size: 14px;
        &:nth-last-of-type(2) {
          margin-right: 6px;
        }
      }
      span {
        font-size: 14px;
      }
      .address {
        margin-top: 20px;
        padding-right: 0;

        span {
          padding-right: 15px;
        }
      }
    }
  }

  .header-section {
    &__content {
      height: auto;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      .header-logo-wrapper {
        .header-logo-desktop {
          display: none;
        }
        .header-logo-mobile {
          display: block;
        }
      }
    }
    .header-contacts {
      padding-left: 0;
      padding-right: 15px;
      display: flex;
      flex-direction: row;
      height: 42.5px;
      justify-content: flex-end;
      align-items: flex-end;
      .header-contacts-desktop {
        display: none;
      }
      .header-contacts-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #334055;
        display: block;
        position: relative;
        .mobile-logo-btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          svg {
            path {
              transition: all 0.3s ease-in-out;
              fill: transparent;
            }
          }
        }
        .mobile-logo-btn.is-active {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      .fb-logo-mobile {
        display: flex;
        svg {
          path {
            transition: all 0.3s ease-in-out;
            fill: transparent;
          }
        }
      }
      .phones-block {
        display: none;
        padding-right: 12px;
        padding-top: 4px;
        position: absolute;
        bottom: -71px;
        right: 0;
        width: 122px;
        height: 57px;
        border-radius: 10px;
        background-color: #334055;
        &::before {
          position: absolute;
          content: "";
          top: -10px;
          right: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 9.5px 9.55px 9.5px;
          border-color: transparent transparent #334055 transparent;
        }
        a {
          font-family: $font-mon-med;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
  .header-section.header-black .header-contacts .header-contacts-mobile .mobile-logo-btn.is-active svg path {
    fill: #282523;
  }
  .header-section.header-black.header-contacts .header-contacts-desktop #online-consult-header {
    color: #282523;
  }

  .header-section.header-black {
    .header-contacts .header-contacts-mobile {
      background-color: #ffffff;
    }
  }
  .header-section.header-black .header-contacts .phones-block {
    background-color: #ffffff;
    &::before {
      border-color: transparent transparent #ffffff transparent;
    }
    a {
      color: #282523;
    }
  }
  .home {
    .background-img {
      .back-decktop {
        display: none;
      }
      .back-mobile {
        display: block;
      }
    }
    &__content {
      &__title {
        margin-bottom: 30px;
      }
      h1 {
        font-size: 29px;
        line-height: 43px;
        &::before,
        &::after {
          width: 13px;
          border-width: 2px;
        }
      }
      &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .online-consult {
          margin-right: 0;
          margin-bottom: 20px;
          // padding:  8px 33px  7px;
          width: 260px;
          height: 40px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .online-registration {
          width: 260px;
          height: 40px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .about {
    .container {
      height: 100%;
      margin-top: 200px;
      overflow-y: scroll;
      padding-bottom: 161px;
    }
    .background-img {
      .back-decktop {
        display: none;
      }
      .back-mobile {
        display: block;
      }
    }
    &__content {
      padding-left: 45px;
      .about-content-wrapper {
        &::before {
          width: 13px;
          border-top: 2px solid #ebb85e;
          border-left: 2px solid #ebb85e;
          border-bottom: 2px solid #ebb85e;
        }
      }
      h2 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 12px;
        padding-top: 8px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__banner {
      padding-left: 45px;
      padding-right: 15px;
      margin-top: 60px;
      .banner-decoration {
        svg {
          display: none;
        }
      }
    }
  }
  .gallery {
    .background-img {
      .back-decktop {
        display: none;
      }
      .back-mobile {
        display: block;
      }
    }

    .gallery-wrapper {
      height: 100%;
      margin-top: 200px;
      overflow-y: scroll;
      padding-bottom: 161px;
    }
    &__title {
      &::before {
        height: 43px;
        width: 13px;
        border-top: 2px solid #ebb85e;
        border-left: 2px solid #ebb85e;
        border-bottom: 2px solid #ebb85e;
      }
      &::after {
        width: 13px;
        height: 43px;
        border-top: 2px solid #ebb85e;
        border-right: 2px solid #ebb85e;
        border-bottom: 2px solid #ebb85e;
      }
      h2 {
        font-size: 24px;
        line-height: 43px;
        margin-bottom: 0;
      }
    }

    .gallery-slider-desktop {
      top: 20%;
      #slick-slide-control01 {
        margin: 0 4px;
      }
      .slick-dots {
        margin-top: 10px;
        button {
          margin: 0 4px;
        }
      }
      a {
        padding: 0 4px 4px;
      }
    }
  }
  .contacts {
    .container {
      padding: 0;
      height: 100%;
      margin-top: 200px;
      overflow-y: scroll;
      padding-bottom: 161px;
    }
    .background-img {
      .back-decktop {
        display: none;
      }
      .back-mobile {
        display: block;
      }
    }
    &__content {
      flex-direction: column;
      .contacts-title {
        margin-left: 0;
        margin-bottom: 32px;

        h2 {
          font-size: 24px;
          line-height: 43px;
          margin-bottom: 0;
          width: 179px;
          text-align: center;
          &::before {
            height: 43px;
            width: 13px;
            border-top: 2px solid #ebb85e;
            border-left: 2px solid #ebb85e;
            border-bottom: 2px solid #ebb85e;
          }
          &::after {
            width: 13px;
            height: 43px;
            border-top: 2px solid #ebb85e;
            border-right: 2px solid #ebb85e;
            border-bottom: 2px solid #ebb85e;
          }
        }
      }

      .contacts-info {
        margin-left: 0;
        .contacts-info-desktop {
          display: none;
        }
        .contacts-info-mobile {
          display: flex;
          flex-direction: column;
          div {
            span {
              margin-right: 13px;
            }
            a {
              font-size: 14px;
            }
            &:nth-child(1) {
              margin-bottom: 20px;
              span {
                margin-right: 19px;
              }
            }
          }
        }
      }
    }
    &__map {
      #map {
        height: 290px;
        border: none;
        border-bottom: 3px solid #ffffff;
        border-top: 3px solid #ffffff;
      }
    }
  }
  .consultation {
    .background-img {
      .back-decktop {
        display: none;
      }
      .back-mobile {
        display: block;
      }
    }
    .container {
      height: 100%;
      margin-top: 200px;
      overflow-y: scroll;
      padding-bottom: 161px;
    }
    &__content {
      &__title {
        display: flex;
        margin: 0 auto;
        width: 240px;
        margin-bottom: 12px;
        h2 {
          position: relative;
          font-size: 24px;
          line-height: 43px;
          width: 100%;
          &::before {
            height: 43px;
            width: 13px;
            border-top: 2px solid #ebb85e;
            border-left: 2px solid #ebb85e;
            border-bottom: 2px solid #ebb85e;
          }
          &::after {
            width: 13px;
            height: 43px;
            border-top: 2px solid #ebb85e;
            border-right: 2px solid #ebb85e;
            border-bottom: 2px solid #ebb85e;
          }
        }
      }
      &__form {
        form {
          input {
            font-size: 14px !important;
          }
          .user-name {
            div {
              label {
                font-size: 14px;
              }
            }
          }
          .user-data {
            div {
              label {
                font-size: 14px;
              }
            }
          }
          .text-area-wrapp {
            label {
              font-size: 14px;
            }
          }
          p {
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 29px;
          }
        }
      }
    }
  }
  .info {
    .background-img {
      .back-decktop {
        display: none;
      }
      .back-mobile {
        display: block;
      }
    }
    .container {
      height: 100%;
      margin-top: 200px;
      overflow-y: scroll;
      padding-bottom: 161px;
    }
    &__content {
      &__title {
        margin-bottom: 25px;
        h2 {
          font-size: 24px;
          line-height: 43px;
          &::before {
            height: 43px;
            width: 13px;
            border-top: 2px solid #ebb85e;
            border-left: 2px solid #ebb85e;
            border-bottom: 2px solid #ebb85e;
          }
          &::after {
            height: 43px;
            width: 13px;
            border-top: 2px solid #ebb85e;
            border-right: 2px solid #ebb85e;
            border-bottom: 2px solid #ebb85e;
          }
        }
      }
      &__main {
        flex-direction: column;
        margin-bottom: 0;
        div {
          max-width: 244px;
          padding: 20px 46px;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
          h3 {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 26px;
          }
          p {
            font-size: 12px;
            line-height: 16px;
          }
        }
        .price-info {
          margin-right: 0;
          margin-bottom: 20px;
          &::after {
            height: 2px;
          }
        }
      }
    }
  }
  .footer-section {
    height: 60px;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    background-color: #2f3e55;
    -webkit-box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.36);
    box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.36);
    .consult-link-desctop {
      display: none;
    }
    .consult-link-mobile {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -90px;
      right: 30px;
      span {
        font-size: 9px;
        color: #ffffff;
      }
      svg {
        width: 60px;
        height: 60px;
      }
    }
    .container {
      height: 60px;
      justify-content: center;
    }
    .arrows {
      width: 100%;
      padding: 0 20px;
      .arrow {
        &::before {
          border-color: #ebb85e;
        }
      }
      .slide-counter {
        padding-right: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .curent-slide {
          color: #ebb85e;
        }
      }
    }
    .load-line {
      display: none;
    }
    .address {
      display: none;
    }
  }
  .footer-section.footer-black {
    background-color: #ffffff;
    -webkit-box-shadow: 0px -5px 20px 0px rgba(85, 53, 5, 0.15);
    -moz-box-shadow: 0px -5px 20px 0px rgba(85, 53, 5, 0.15);
    box-shadow: 0px -5px 20px 0px rgba(85, 53, 5, 0.15);
    .consult-link-mobile {
      span {
        color: #282523;
      }
    }
  }
}

@media screen and (max-width: $sz-575) {
  .navigation-menu-block__bottom {
    width: 100%;
    left: 0;
  }
  .header-section .header-contacts .phones-block::before {
    top: -9px;
  }
  .consultation__content__form form div label {
    font-size: 12px !important;
  }
  .header-section {
    padding-bottom: 8px;
    background: transparent;
    // background: linear-gradient(180deg, rgba(55, 72, 99, 1) 0%, rgba(60, 72, 95, 1) 100%);
  }
  .header-section.header-black {
    background: transparent;
    padding-bottom: 8px;
  }
  .gallery {
    .gallery-slider-desktop {
      display: none;
    }
    .gallery-wrapper {
      padding: 0;
    }
    .gallery-slider-mobile {
      display: block;
      margin-top: 0;
      top: 9.5%;
      a {
        padding: 0;
        &:focus {
          outline: none;
        }
      }
      .slick-slide {
        &:focus {
          outline: none;
        }
        div {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .header-section__content .header-contacts .fb-logo-mobile {
    margin-left: 4px;
  }
}
